import {
  // eslint-disable-next-line max-len
  Box, Container, Card, Grid, Typography, Button, Table, TableHead, TableRow, TableCell, Link, TableBody
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAuth, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getDatabase, set, ref, push, onValue, remove } from 'firebase/database';
import { Scrollbar } from '../components/scrollbar';
import '../style.css';
import '../firebase';

const db = getDatabase();

export const Settings = () => {
  const auth = getAuth();
  const userlogged = auth.currentUser;
  const navigate = useNavigate();
  const [showuser, setShowuser] = useState(false);
  const [showproject, setShowproject] = useState(false);
  const handleCloseProject = () => setShowproject(false);
  const handleShowProject = () => setShowproject(true);

  const handleCloseUser = () => setShowuser(false);
  const handleShowUser = () => setShowuser(true);
  const [projets, setprojet] = useState([]);
  const [users, setusers] = useState([]);

  const [role, setrole] = useState('');

  useEffect(() => {
    onValue(ref(db, '/projet/'), (snapshot) => {
      setprojet([]);
      const datas = snapshot.val();
      Object.values(datas).forEach((data) => {
        setprojet((prevState) => [...prevState, Object.assign(data, Object.keys(datas))]);
      });
    });
    onValue(ref(db, '/users/'), (snapshot) => {
      setusers([]);
      const datas = snapshot.val();
      Object.values(datas).forEach((data) => {
        setusers((prevState) => [...prevState, data]);
      });
    });
    onValue(ref(db, `/users/${userlogged.uid}/role`), (snapshot) => {
      const datarole = snapshot.val();
      setrole(datarole);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmitProject = (data) => {
    push(ref(db, 'projet/'), {
      name: data.name,
    });
    handleCloseProject();
  };
  const onSubmitAuth = (data) => {
    createUserWithEmailAndPassword(auth, data.email, 'MdPT3mP3CréaTime')
      .then((userCredential) => {
        sendPasswordResetEmail(auth, data.email);
        const { user } = userCredential;
        console.log(user);
        set(ref(db, `users/${user.uid}`), {
          name: data.name,
          uid: user.uid,
          email: data.email,
          role: 'Utilisateur'
        });
        signOut(auth).then(() => {
          navigate('/login');
        }).catch((error) => {
          console.log(error);
        });
      // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      // ..
      });
    handleCloseProject();
  };

  function suppProjet(uid) {
    remove(ref(db, `/projet/${projets[0][uid]}`));
  }

  if (role === 'Administrateur') {
    return (
      <>
        <Modal
          show={showproject}
          onHide={handleCloseProject}
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body>

            <form onSubmit={handleSubmit(onSubmitProject)}>
              <textarea
                {...register('name', {})}
                id="myTextarea"
                type="text"
                placeholder="Nom du projet..."
              />
              <input
                className="button green"
                type="submit"
              />
            </form>

          </Modal.Body>
        </Modal>
        <Modal
          show={showuser}
          onHide={handleCloseUser}
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body>

            <form onSubmit={handleSubmit(onSubmitAuth)}>
              <textarea
                {...register('name', {})}
                id="myTextarea"
                type="text"
                placeholder="Nom..."
              />
              <textarea
                {...register('email', {})}
                id="myTextarea"
                type="email"
                placeholder="Email..."
              />
              <input
                className="button green"
                type="submit"
              />
            </form>

          </Modal.Body>
        </Modal>
        <Grid
          container
          spacing={2}
        >
          <Grid xs={6}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                pb: 3,
                pt: 8
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 3
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    Utilisateur
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleShowUser}
                  >
                    Add User
                  </Button>

                </Box>
                <Card variant="outlined" />
                <Scrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          UID
                        </TableCell>
                        <TableCell>
                          Nom
                        </TableCell>
                        <TableCell>
                          Email
                        </TableCell>
                        <TableCell>
                          Role
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow>
                          <TableCell>
                            <Link
                              color="inherit"
                              to="#"
                              underline="none"
                              variant="subtitle2"
                            >
                              {user.uid}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Box>
                              {user.name}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {user.email}
                          </TableCell>
                          <TableCell>
                            <Box>
                              {user.role}
                            </Box>
                          </TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </Container>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                pb: 3,
                pt: 8
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 3
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    Projet
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleShowProject}
                  >
                    Add Project
                  </Button>

                </Box>
                <Card variant="outlined" />
                <Scrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          ID
                        </TableCell>
                        <TableCell>
                          Nom
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projets.map((projet, index) => (
                        <TableRow>
                          <TableCell>
                            <Link
                              color="inherit"
                              to="#"
                              underline="none"
                              variant="subtitle2"
                            >
                              {index + 1}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Box>
                              {projet.name}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              color="error"
                              onClick={() => suppProjet(index)}
                              variant="outlined"
                              startIcon={<DeleteIcon />}
                            >
                              Supprimer
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <h3>Vous n êtes pas administrateur</h3>
  );
};
