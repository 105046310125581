/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import { CSVLink } from 'react-csv';
import { Button, Card, Container, Divider, Typography,
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Link as RouterLink } from 'react-router-dom';
import '../style.css';
import '../firebase';
import { Scrollbar } from '../components/scrollbar';

const db = getDatabase();
export const Orders = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const [ordertime, setOrdertime] = useState([]);
  const [taskuser, settaskuser] = useState('All');
  const [taskselect, settaskselect] = useState('All');
  const [taskdatestart, setdatestart] = useState('00/00/00');
  const [taskdateend, setdateend] = useState('00/00/00');
  const [role, setrole] = useState('');
  const [minutes, setminutes] = useState(0);
  const [csvexport, setcsv] = useState([]);
  const [filenamecsv, setfilenamecsv] = useState('export.csv');
  useEffect(() => {
    settaskuser(auth.currentUser.uid);
    onValue(ref(db, `/users/${user.uid}/role`), (snapshot) => {
      const datarole = snapshot.val();
      setrole(datarole);
    });
  }, []);

  useEffect(() => {
    onValue(ref(db, '/task/'), (snapshot) => {
      setOrdertime([]);
      setminutes(0);
      const datas = snapshot.val();
      Object.values(datas).forEach((data) => {
        if (data.uid === user.uid) { // TRI PAR UTILISATEUR  //
          if (data.project === taskselect || taskselect === 'All') {
            setminutes((prevState) => (parseFloat(prevState) + parseFloat(data.timeselect)));
            setOrdertime((prevState) => [...prevState, data]);
          }
        }
      });
    });
  }, []);

  useEffect(() => {
    if (role === 'Administrateur') {
      onValue(ref(db, '/task/'), (snapshot) => {
        setOrdertime([]);
        setcsv([]);
        setminutes(0);
        const datas = snapshot.val();
        Object.values(datas).forEach((data) => {
          if (data.uid === taskuser || taskuser === 'All') { // FILTRE COMPLET //
            if (data.project === taskselect || taskselect === 'All') {
              // eslint-disable-next-line max-len
              const date = new Date(data.date.substring(6, 10), data.date.substring(3, 5) - 1, data.date.substring(0, 2));
              if ((taskdatestart <= date && date <= taskdateend)) {
                setminutes((prevState) => (parseFloat(prevState) + parseFloat(data.timeselect)));
                setOrdertime((prevState) => [...prevState, data]);
                // eslint-disable-next-line max-len
                setcsv((prevState) => [...prevState, { time: data.timeselect, date: data.date, name: data.name, project: data.project, desc: data.desc }]);
              }
            }
          }
          setfilenamecsv(`${taskdatestart.toLocaleDateString('fr').toString().substring(0, 2)}-${taskdatestart.toLocaleDateString('fr').toString().substring(3, 5)}-${taskdatestart.toLocaleDateString('fr').toString().substring(6, 10)} ${taskdateend.toLocaleDateString('fr').toString().substring(0, 2)}-${taskdateend.toLocaleDateString('fr').toString().substring(3, 5)}-${taskdateend.toLocaleDateString('fr').toString().substring(6, 10)}`);
        });
      });
    } else {
      onValue(ref(db, '/task/'), (snapshot) => {
        setOrdertime([]);
        setminutes(0);
        const datas = snapshot.val();
        Object.values(datas).forEach((data) => {
          if (data.uid === user.uid) { // TRI PAR UTILISATEUR  //
            if (data.project === taskselect || taskselect === 'All') {
              setminutes((prevState) => (parseFloat(prevState) + parseFloat(data.timeselect)));
              setOrdertime((prevState) => [...prevState, data]);
            }
          }
        });
      });
    }
  }, [taskuser, taskselect, taskdateend, taskdatestart]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = `${date.slice(8, 10)}/${
    date.slice(5, 7)}/${
    date.slice(0, 4)}`;
  const [projets, setprojet] = useState([]);

  const [users, setusers] = useState([]);
  useEffect(() => {
    onValue(ref(db, '/projet/'), (snapshot) => {
      setprojet([]);
      const datas = snapshot.val();
      Object.values(datas).forEach((data) => {
        setprojet((prevState) => [...prevState, data]);
      });
    });
    onValue(ref(db, '/users/'), (snapshot) => {
      setusers([]);
      const datas = snapshot.val();
      Object.values(datas).forEach((data) => {
        setusers((prevState) => [...prevState, data]);
      });
    });
  }, []);

  function addOrders(desc, project, tag, timeselect) {
    onValue(ref(db, `/users/${user.uid}`), (snapshot) => {
      const datas = snapshot.val();
      push(ref(db, 'task/'), {
        date: nDate,
        desc,
        name: datas.name,
        uid: datas.uid,
        project,
        tag,
        timeselect
      });
    });
  }
  const headers = [
    { label: 'Temps (minutes)', key: 'time' },
    { label: 'Date', key: 'date' },
    { label: 'Nom', key: 'name' },
    { label: 'Projet', key: 'project' },
    { label: 'Description', key: 'desc' }
  ];

  const csvReport = {
    data: csvexport,
    headers,
    filename: filenamecsv
  };

  // eslint-disable-next-line no-unused-vars
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    addOrders(data.desc, data.project, data.tag, data.timeselect);
    handleClose();
  };

  const onSubmitUser = (data) => {
    settaskuser(data.usersa);
  };

  const onSubmitTaskselect = (data) => {
    settaskselect(data.projetselect);
  };
  const onSubmitTimeSelect = (data) => {
    setdatestart(new Date(data.datestart));
    setdateend(new Date(data.dateend));
  };
  if (role === 'Administrateur') {
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Header closeButton />
          <Modal.Body>

            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="containerorder">
                <div className="button-wrap">
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="5"
                    className="hidden radio-label"
                    id="a-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="a-button"
                  >
                    <h1>5min</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="15"
                    className="hidden radio-label"
                    id="b-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="b-button"
                  >
                    <h1>15min</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="30"
                    className="hidden radio-label"
                    id="c-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="c-button"
                  >
                    <h1>30min</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="60"
                    className="hidden radio-label"
                    id="d-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="d-button"
                  >
                    <h1>1h</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="90"
                    className="hidden radio-label"
                    id="e-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="e-button"
                  >
                    <h1>1h30</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="120"
                    className="hidden radio-label"
                    id="f-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="f-button"
                  >
                    <h1>2h</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="150"
                    className="hidden radio-label"
                    id="g-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="g-button"
                  >
                    <h1>2h30</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="180"
                    className="hidden radio-label"
                    id="h-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="h-button"
                  >
                    <h1>3h</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="210"
                    className="hidden radio-label"
                    id="i-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="i-button"
                  >
                    <h1>3h30</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="240"
                    className="hidden radio-label"
                    id="j-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="j-button"
                  >
                    <h1>4h</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="270"
                    className="hidden radio-label"
                    id="k-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="k-button"
                  >
                    <h1>4h30</h1>
                  </label>
                  <input
                    {...register('timeselect')}
                    type="radio"
                    value="300"
                    className="hidden radio-label"
                    id="l-button"
                    required
                  />
                  <label
                    className="button-label"
                    htmlFor="l-button"
                  >
                    <h1>5h</h1>
                  </label>
                </div>
              </div>
              <select
                className="select"
                {...register('tag')}
              >
                <option value="Dev">Dev</option>
                <option value="Veille">Veille</option>
                <option value="Calling">Calling</option>
              </select>
              <select
                className="select"
                {...register('project')}
              >
                {projets.map((projet) => (
                  <option value={projet.name}>{projet.name}</option>
                ))}
              </select>
              <textarea
                {...register('desc', {})}
                id="myTextarea"
                rows="1"
                placeholder="Description..."
                maxLength="400"
              />

              <input
                className="button green"
                type="submit"
              />
            </form>

          </Modal.Body>
        </Modal>
        <Helmet>
          <title>Ecreatime</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            pb: 3,
            pt: 8
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                mb: 3
              }}
            >
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Tasks
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <form
                onSubmit={handleSubmit(onSubmitUser)}
                className="userselect"
              >
                <select
                  className="select"
                  {...register('usersa')}
                >
                  <option value="All">All</option>
                  {users.map((usera) => (
                    <option value={usera.uid}>{usera.name}</option>
                  ))}
                </select>
                <input
                  className="button green"
                  value="Filtre Utilisateur"
                  type="submit"
                />
              </form>
              <form
                onSubmit={handleSubmit(onSubmitTaskselect)}
                className="userselect"
              >
                <select
                  className="select"
                  {...register('projetselect')}
                >
                  <option value="All">All</option>
                  {projets.map((projet) => (
                    <option value={projet.name}>{projet.name}</option>
                  ))}
                </select>
                <input
                  className="button green"
                  value="Filtre Projet"
                  type="submit"
                />
              </form>

              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleShow}
              >
                Add Task
              </Button>
            </Box>
            <form
              onSubmit={handleSubmit(onSubmitTimeSelect)}
              className="dateselect"
            >

              <input
                {...register('datestart')}
                type="date"
              />
              <input
                {...register('dateend')}
                type="date"
              />
              <input
                className="button green"
                value="Filtre Date"
                type="submit"
              />
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </form>

            <Card variant="outlined">

              <Divider />
              <Divider />
            </Card>
          </Container>
        </Box>
        <div>
          <Scrollbar>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Temps (minutes)
                  </TableCell>
                  <TableCell>
                    Date
                  </TableCell>
                  <TableCell>
                    Nom
                  </TableCell>
                  <TableCell>
                    Projet/client
                  </TableCell>
                  <TableCell>
                    Descriptif
                  </TableCell>
                  <TableCell>
                    Tag
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ordertime.slice(0).reverse().map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to="#"
                        underline="none"
                        variant="subtitle2"
                      >
                        {`${order.timeselect} min`}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {`${order.date}`}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {`${order.name}`}
                    </TableCell>
                    <TableCell>
                      {`${order.project}`}
                    </TableCell>
                    <TableCell>
                      {`${order.desc}`}
                    </TableCell>
                    <TableCell>
                      {`${order.tag}`}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    Total Temps :
                    {' '}
                    {minutes}
                    {' '}
                    minutes
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Scrollbar>
        </div>
      </>
    );
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Body>

          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="containerorder">
              <div className="button-wrap">
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="5"
                  className="hidden radio-label"
                  id="a-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="a-button"
                >
                  <h1>5min</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="15"
                  className="hidden radio-label"
                  id="b-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="b-button"
                >
                  <h1>15min</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="30"
                  className="hidden radio-label"
                  id="c-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="c-button"
                >
                  <h1>30min</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="60"
                  className="hidden radio-label"
                  id="d-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="d-button"
                >
                  <h1>1h</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="90"
                  className="hidden radio-label"
                  id="e-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="e-button"
                >
                  <h1>1h30</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="120"
                  className="hidden radio-label"
                  id="f-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="f-button"
                >
                  <h1>2h</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="150"
                  className="hidden radio-label"
                  id="g-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="g-button"
                >
                  <h1>2h30</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="180"
                  className="hidden radio-label"
                  id="h-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="h-button"
                >
                  <h1>3h</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="210"
                  className="hidden radio-label"
                  id="i-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="i-button"
                >
                  <h1>3h30</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="240"
                  className="hidden radio-label"
                  id="j-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="j-button"
                >
                  <h1>4h</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="270"
                  className="hidden radio-label"
                  id="k-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="k-button"
                >
                  <h1>4h30</h1>
                </label>
                <input
                  {...register('timeselect')}
                  type="radio"
                  value="300"
                  className="hidden radio-label"
                  id="l-button"
                  required
                />
                <label
                  className="button-label"
                  htmlFor="l-button"
                >
                  <h1>5h</h1>
                </label>
              </div>
            </div>
            <select
              className="select"
              {...register('tag')}
            >
              <option value="Dev">Dev</option>
              <option value="Veille">Veille</option>
              <option value="Calling">Calling</option>
            </select>
            <select
              className="select"
              {...register('project')}
            >
              {projets.map((projet) => (
                <option value={projet.name}>{projet.name}</option>
              ))}
            </select>
            <textarea
              {...register('desc', {})}
              id="myTextarea"
              rows="1"
              placeholder="Description..."
              maxLength="400"
            />

            <input
              className="button green"
              type="submit"
            />
          </form>

        </Modal.Body>
      </Modal>
      <Helmet>
        <title>Ecreatime</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          pb: 3,
          pt: 8
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 3
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              Tasks
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <form
              onSubmit={handleSubmit(onSubmitTaskselect)}
              className="userselect"
            >
              <select
                className="select"
                {...register('projetselect')}
              >
                <option value="All">All</option>
                {projets.map((projet) => (
                  <option value={projet.name}>{projet.name}</option>
                ))}
              </select>
              <input
                className="button green"
                value="Filtre Projet"
                type="submit"
              />
            </form>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleShow}
            >
              Add Task
            </Button>
          </Box>
          <Card variant="outlined">

            <Divider />
            <Divider />
          </Card>
        </Container>
      </Box>
      <div>
        <Scrollbar>
          <Table sx={{ minWidth: 1000 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Temps (minutes)
                </TableCell>
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Nom
                </TableCell>
                <TableCell>
                  Projet/client
                </TableCell>
                <TableCell>
                  Descriptif
                </TableCell>
                <TableCell>
                  Tag
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordertime.slice(0).reverse().map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {`${order.timeselect} min`}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {`${order.date}`}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {`${order.name}`}
                  </TableCell>
                  <TableCell>
                    {`${order.project}`}
                  </TableCell>
                  <TableCell>
                    {`${order.desc}`}
                  </TableCell>
                  <TableCell>
                    {`${order.tag}`}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  Total Temps :
                  {' '}
                  {minutes}
                  {' '}
                  minutes
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Scrollbar>
      </div>
    </>
  );
};
