/* eslint-disable react/button-has-type */
import React from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import '../login.css';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ReactComponent as YourSvg } from '../components/logo-site-2018login.svg';

import '../firebase';
// import { getDatabase } from 'firebase/database';

export const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    ).then(
      () => {
        navigate('/dashboard');
      }
    ).catch((err) => {
      const errorCode = err.code;
      if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found') {
        // eslint-disable-next-line no-alert
        alert('Compte incorrect');
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Ecreatime</title>
      </Helmet>

      <div className="container">

        <div className="screen">
          <div className="screen__content">
            <form
              className="login"
              onSubmit={handleSubmit(onSubmit)}
            >
              <YourSvg />
              <div className="login__field">
                <i className="login__icon fas fa-user" />
                <input
                  {...register('email', {})}
                  type="text"
                  placeholder="Email..."
                  className="login__input"
                />
              </div>
              <div className="login__field">
                <i className="login__icon fas fa-lock" />
                <input
                  {...register('password', {})}
                  type="password"
                  placeholder="Password..."
                  className="login__input"
                  autoComplete="current-password"
                />
              </div>
              <input
                type="submit"
                className="button login__submit"
                value="Connexion"
              />
              <i className="button__icon fas fa-chevron-right" />
            </form>
          </div>
          <div className="screen__background">
            <span className="screen__background__shape screen__background__shape4" />
            <span className="screen__background__shape screen__background__shape3" />
            <span className="screen__background__shape screen__background__shape2" />
            <span className="screen__background__shape screen__background__shape1" />
          </div>
        </div>
      </div>
    </>
  );
};
