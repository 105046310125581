import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Sidebar } from './sidebar';
import '../firebase';
// import { getDatabase } from 'firebase/database';

const LayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const LayoutContent = styled('div')(
  () => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingLeft: 73,
    paddingTop: '64px'
  })
);

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'auto'
});

export const Layout = () => (
  <LayoutRoot>
    <Sidebar />
    <LayoutContent>
      <LayoutContainer>
        <Outlet />
      </LayoutContainer>
    </LayoutContent>
  </LayoutRoot>
);
