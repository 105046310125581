import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { theme } from './theme';
import { routes } from './routes';
import { routesunlogged } from './routesunlogged';

const App = () => {
  const auth = getAuth();
  let content = useRoutes(routes);
  if (auth.currentUser === null) {
    content = useRoutes(routesunlogged);
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {content}
    </ThemeProvider>
  );
};

export default App;
