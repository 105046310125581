// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBzYg5v_ELkzCreEsWysnjiztajEWdO1hU',
  authDomain: 'ecreatime.firebaseapp.com',
  databaseURL: 'https://ecreatime-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ecreatime',
  storageBucket: 'ecreatime.appspot.com',
  messagingSenderId: '59220692388',
  appId: '1:59220692388:web:77ab594838c3055656c531',
  measurementId: 'G-2BQXNJ1WS0'
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);
